import React from 'react'

const TeleG = () => {
return (
<>
<div className='socialPageWrap'>
<div className='socialBox boxTele'>
<div className='socialLogo'>
<img src='/images/layBack.png' alt='social_logo' />
</div>

<div className='socialImg'>
<img src='/images/telegram.png' alt='social_img' />
</div>

<div className='socialLinkBtn'>
<span className='teleInf'>for more information click below join us button to join our telegram channel.</span>
<a href='https://t.me/layback_247'><button className='teleBtn'>join us telegram</button></a>
</div>

</div>
</div>
</>
)
}

export default TeleG;
