import React from 'react';

const ChatSup = () => {
return (
<>
<div className='socialPageWrap'>
<div className='socialBox boxSup'>
<div className='socialLogo'>
<img src='/images/layBack.png' alt='social_logo' />
</div>

<div className='socialImg'>
<img src='/images/chat.png' alt='social_img' />
</div>

<div className='socialLinkBtn'>
<span className='yelTxt'>for help and support click below chat for support button.</span>
<a href='https://wa.me/+919177918978'><button className='supBtn'>chat for support</button></a>
</div>

</div>
</div>
</>
)
}

export default ChatSup;
